import RequestService from './../Request'
import ClockService from './../Clock'
import AdminAuthService from './../admin/Auth'

const loginInit = phoneOrEmail => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/token/user/init',
			method: 'POST',
			body: {
				phoneOrEmail: phoneOrEmail
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const loginByOTP = (mobileNo, otp) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/token/user/otp',
			method: 'POST',
			body: {
				mobileNo: mobileNo,
				otp: otp
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				if (res.state === 'success') {
					localStorage.setItem('user', JSON.stringify(res.user))
					localStorage.setItem('userKey', res.token)
					localStorage.setItem(
						'userKeyExp',
						(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toString()
					)
					localStorage.setItem('userReturned', res.user.id)
					AdminAuthService.logout()
				}
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const loginByLink = token => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/token/user/link',
			method: 'POST',
			body: {
				token: token
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				if (res.state === 'success') {
					localStorage.setItem('user', JSON.stringify(res.user))
					localStorage.setItem('userKey', res.token)
					localStorage.setItem(
						'userKeyExp',
						(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toString()
					)
					localStorage.setItem('userReturned', res.user.id)
					AdminAuthService.logout()
				}
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const setUserPassword = (userID, userPassword, confPassword) => {
	return new Promise((resolve, reject) => {
		var req = {
			url: '/user-security/password/set',
			method: 'POST',
			body: {
				userID,
				userPassword,
				confPassword
			},
			addToken: false
		}
		RequestService.send(req)
			.then(res => {
				// if (res.state === 'success') {
				// 	localStorage.setItem('user', JSON.stringify(res.user))
				// 	localStorage.setItem('userKey', res.token)
				// 	localStorage.setItem(
				// 		'userKeyExp',
				// 		(new Date().getTime() + 365 * 24 * 60 * 60 * 1000).toString()
				// 	)
				// 	localStorage.setItem('userReturned', res.user.id)
				// 	AdminAuthService.logout()
				// }
				return resolve(res)
			})
			.catch(err => {
				return reject(err)
			})
	})
}

const logout = () => {
	localStorage.removeItem('user')
	localStorage.removeItem('userKey')
	localStorage.removeItem('userKeyExp')
}

const status = () => {
	const user = localStorage.getItem('user')
	const userKey = localStorage.getItem('userKey')
	const userKeyExp = localStorage.getItem('userKeyExp')

	if (
		user &&
		userKey &&
		userKeyExp &&
		parseInt(userKeyExp) >= ClockService.now()
	) {
		return true
	}
	return false
}

export default {
	loginInit,
	loginByOTP,
	loginByLink,
	logout,
	status,
	setUserPassword
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import AppLocale from 'lngProvider'

import PublicApp from './PublicApp'
import UserApp from './UserApp'
// import AdminApp from './AdminApp'

// import AdminSignIn from '../AdminSignIn'
// import AdminResetPassword from '../AdminResetPassword'
// import AdminEmailVerify from '../AdminEmailVerify'
import UserEmailVerify from '../UserEmailVerify'
import {
	onLayoutTypeChange,
	onNavStyleChange,
	setThemeType
} from '../../appRedux/actions/Setting'

import {
	LAYOUT_TYPE_BOXED,
	LAYOUT_TYPE_FRAMED,
	LAYOUT_TYPE_FULL,
	NAV_STYLE_ABOVE_HEADER,
	NAV_STYLE_BELOW_HEADER,
	NAV_STYLE_DARK_HORIZONTAL,
	NAV_STYLE_DEFAULT_HORIZONTAL,
	NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
	THEME_TYPE_DARK
} from '../../constants/ThemeSetting'

// import ClockService from './../../services/Clock'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import ReactGA from 'react-ga4'

// const AdminProtectedRoute = ({ component: Component, location, ...rest }) => (
// 	<Route
// 		{...rest}
// 		render={props => {
// 			const adminToken = localStorage.getItem('adminKey')
// 			const adminTokenExp = parseInt(localStorage.getItem('adminKeyExp'))
// 			const superAdminToken = localStorage.getItem('superAdminKey')
// 			const superAdminTokenExp = parseInt(
// 				localStorage.getItem('superAdminKeyExp')
// 			)
// 			var status = undefined
// 			if (adminToken) {
// 				if (adminTokenExp > ClockService.now()) {
// 					// admin verified
// 					status = 'admin'
// 				}
// 			} else if (superAdminToken) {
// 				if (superAdminTokenExp > ClockService.now()) {
// 					// super admin verified
// 					status = 'superAdmin'
// 				}
// 			}

// 			return status === 'admin' || status === 'superAdmin' ? (
// 				<Component {...props} />
// 			) : (
// 				<Redirect
// 					to={{
// 						pathname: '/admin/signin',
// 						state: { from: location }
// 					}}
// 				/>
// 			)
// 		}}
// 	/>
// )

// const UserProtectedRoute = ({ component: Component, location, ...rest }) => (
// 	<Route
// 		{...rest}
// 		render={props => {
// 			const userToken = localStorage.getItem('userKey')
// 			const userTokenExp = parseInt(localStorage.getItem('userKeyExp'))
// 			if (userToken && userTokenExp > ClockService.now()) {
// 				return <Component {...props} />
// 			}
// 			return (
// 				<Redirect
// 					to={{
// 						pathname: '/user/signin',
// 						state: { from: location }
// 					}}
// 				/>
// 			)
// 		}}
// 	/>
// )

const UserProtectedRoute = ({ component: Component, location, ...rest }) => (
	<Route
		{...rest}
		render={props => {
			const userToken = localStorage.getItem('userKey')
			const userTokenExp = Number(localStorage.getItem('userKeyExp'))

			if (userToken && userTokenExp > Date.now()) {
				return <Component {...props} />
			}

			return (
				<Redirect
					to={{
						pathname: '/auth/signin',
						state: { from: location }
					}}
				/>
			)
		}}
	/>
)

const App = props => {
	const { match, location } = props

	const dispatch = useDispatch()

	const locale = useSelector(({ settings }) => settings.locale)
	const navStyle = useSelector(({ settings }) => settings.navStyle)
	const themeType = useSelector(({ settings }) => settings.themeType)
	const layoutType = useSelector(({ settings }) => settings.layoutType)

	useEffect(() => {
		const params = new URLSearchParams(location.search)
		if (params.has('theme')) {
			dispatch(setThemeType(params.get('theme')))
		}
		if (params.has('nav-style')) {
			dispatch(onNavStyleChange(params.get('nav-style')))
		}
		if (params.has('layout-type')) {
			dispatch(onLayoutTypeChange(params.get('layout-type')))
		}
	}, [dispatch, location.search])

	const setLayoutType = layoutType => {
		if (layoutType === LAYOUT_TYPE_FULL) {
			document.body.classList.remove('boxed-layout')
			document.body.classList.remove('framed-layout')
			document.body.classList.add('full-layout')
		} else if (layoutType === LAYOUT_TYPE_BOXED) {
			document.body.classList.remove('full-layout')
			document.body.classList.remove('framed-layout')
			document.body.classList.add('boxed-layout')
		} else if (layoutType === LAYOUT_TYPE_FRAMED) {
			document.body.classList.remove('boxed-layout')
			document.body.classList.remove('full-layout')
			document.body.classList.add('framed-layout')
		}
	}

	const setNavStyle = navStyle => {
		if (
			navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
			navStyle === NAV_STYLE_DARK_HORIZONTAL ||
			navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
			navStyle === NAV_STYLE_ABOVE_HEADER ||
			navStyle === NAV_STYLE_BELOW_HEADER
		) {
			document.body.classList.add('full-scroll')
			document.body.classList.add('horizontal-layout')
		} else {
			document.body.classList.remove('full-scroll')
			document.body.classList.remove('horizontal-layout')
		}
	}

	if (themeType === THEME_TYPE_DARK) {
		document.body.classList.add('dark-theme')
	}
	// if (location.pathname === '/') {
	// 	return (<Redirect to={'/home'} />)
	// }

	setLayoutType(layoutType)

	setNavStyle(navStyle)

	// ReactGA.initialize('UA-139412391-1')
	// ReactGA.pageview(window.location.pathname + window.location.search)

	ReactGA.initialize('G-LFJY8Z7CBD')
	ReactGA.send({
		hitType: 'pageview',
		page: `${window.location.pathname + window.location.search}`,
		title: 'As-Sunnah Foundation'
	})

	const currentAppLocale = AppLocale[locale.locale]

	return (
		<ConfigProvider locale={currentAppLocale.antd}>
			<IntlProvider
				locale={currentAppLocale.locale}
				messages={currentAppLocale.messages}
			>
				<MessengerCustomerChat
					pageId='366754317225219'
					appId='4039464869469816'
					htmlRef={window.location.href}
				/>
				<Switch>
					{/* <Route exact path='/admin/signin' component={AdminSignIn} />
					<Route
						exact
						path='/admin/resetpassword'
						component={AdminResetPassword}
					/> */}
					{/* <Route
						exact
						path='/admin/verify/:token'
						component={AdminEmailVerify}
					/> */}
					{/* <Route exact path='/user/signin' component={UserSignIn} /> */}
					<Route exact path='/user/verify/:token' component={UserEmailVerify} />
					<UserProtectedRoute
						path={`${match.url}user`}
						location={location}
						component={UserApp}
					/>
					{/* <AdminProtectedRoute
						path={`${match.url}admin`}
						location={location}
						component={AdminApp}
					/> */}
					{/* <Route path={`${match.url}auth`} component={AuthApp} /> */}
					<Route path={match.url} component={PublicApp} />
				</Switch>
			</IntlProvider>
		</ConfigProvider>
	)
}

export default App

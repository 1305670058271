import React from 'react'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import IntlMessages from '../../util/IntlMessages'

const UserSecondaryNav = ({ memberType }) => {
	const { pathname } = useSelector(({ common }) => common)
	const selectedKeys = pathname.substr(1)
	const defaultOpenKeys = selectedKeys.split('/')[1]
	return (
		<Menu
			disabledOverflow={[true]}
			defaultOpenKeys={[defaultOpenKeys]}
			selectedKeys={[selectedKeys]}
			mode='horizontal'
		>
			{memberType === 'user' ? (
				<>
					<Menu.Item key='user'>
						<Link to='/user' style={{ color: 'black' }}>
							<IntlMessages id='nav.profile' />
						</Link>
					</Menu.Item>
					<Menu.Item key='user/donations'>
						<Link to='/user/donations' style={{ color: 'black' }}>
							<IntlMessages id='nav.donations' />
						</Link>
					</Menu.Item>
					<Menu.Item key='user/subscriptions'>
						<Link to='/user/subscriptions' style={{ color: 'black' }}>
							<IntlMessages id='nav.subscriptions' />
						</Link>
					</Menu.Item>
					<Menu.Item key='user/donation-projects'>
						<Link to='/user/donation-projects' style={{ color: 'black' }}>
							<IntlMessages id='nav.donation.projects' />
						</Link>
					</Menu.Item>
					{/* <Menu.Item key='user/change-password'>
						<Link to='/user/change-password' style={{ color: 'black' }}>
							<IntlMessages id='nav.account.change.password' />
						</Link>
					</Menu.Item> */}
					{/* <Menu.Item key='userDonations'>
						<Link to='/user/donations' style={{color: 'black'}}>
							<IntlMessages id='nav.donations' />
						</Link>
					</Menu.Item> */}
				</>
			) : null}
		</Menu>
	)
}

UserSecondaryNav.propTypes = {}

export default UserSecondaryNav
